import React, { Component, PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";

import MenuApp from "../../vidgets/menu/MenuApp";
import SignIn from "../../vidgets/button/SignIn";
import LogoApi from "../../vidgets/logo/LogoApi";
import { AppRoutes } from "../../app/AppRoutes";
import MobileMenuApp from "../../vidgets/menu/MobileMenuApp";

import { Box, Grid, Typography } from "@material-ui/core";
import "./MyDataUsView.scss";
import AppConstants, { MenuOptions } from "../../app/AppConstants";
import SliderClient from "../../vidgets/slider/SliderClient";
import { IAppStore } from "../../stores/AppStore";
import { inject, observer } from "mobx-react";
import Footer from "../../vidgets/footer/Footer";
import MyDataUsCard from "../../vidgets/card/myData/MyDataUsCard";
import RestApi from "../../services/RestApi";
import { ILogin } from "../../data/models";
import CircularLoading from "../../vidgets/loading/CircularLoadind";
import DeleteAccDialog from "../../vidgets/dialog/DeleteAccDialog";
export interface IProps extends RouteComponentProps {
  appStore?: IAppStore;
}
export interface IStore {}

@inject("appStore")
@observer
export default class MyDataUsView extends PureComponent<IProps, IStore> {
  store: IAppStore;
  state = {
    showDeleteAccDialog: false,
  };
  constructor(props: IProps) {
    super(props);
    this.store = props.appStore!;
    this.onSignIn = this.onSignIn.bind(this);
    this.onDeleteAcc = this.onDeleteAcc.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * onSignOut
   * @public
   */
  public onSignOut(): void {
    this.props.history.push(AppRoutes.HOME);
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.store.clear();
    if (window.HelpCrunch) {
      console.log("window.HelpCrunch exist");
      window.HelpCrunch("logout");
    }
  }

  public onDeleteAcc(): void {
    const userId: string =
      sessionStorage.getItem(AppConstants.USER_ID) ||
      this.store.getUserParam?.userId ||
      this.store.getUserIdSignup ||
      "";
    const userParam: ILogin | null = JSON.parse(
      sessionStorage.getItem(AppConstants.USER_PARAM) ?? "null"
    );
    const clientId: string =
      this.store.getClientUserToken?.clientId ||
      this.store.getUserParam?.clientId ||
      userParam?.clientId ||
      "";
    if (userId) {
      this.store.setLoading(true);
      const boby = {
        clientId: +clientId,
      };
      RestApi.deleteAccount(boby)
        .then((res) => {
          console.log("res.isSuccess", res);
          if (res.isSuccess) {
            console.log("RestApi.restorePassword.isSuccess", res.isSuccess);
            this.setState({ showDeleteAccDialog: true });
            this.store.setOpenDialodCookies(true);
            this.store.setMessageDialogCookies(
              "Thank you, your request has been sent. Ugo will respond within 7 days by email to confirm your account has been deleted."
            );
          } else if (!res.isSuccess && !res.data) {
            console.error(`No confirmation user data: ${res} `);
          } else if (res.error) {
            this.store.setUpDialog(res.error.message ?? "Wrong user data");
          }
        })
        .catch((err) => {
          this.store.setLoading(false);
          console.error(
            `${err.message}, ${err.name}, status: ${err.status}, code: ${err.code}, hint: ${err.hint}`
          );
        })
        .finally(() => {
          this.store.setLoading(false);
        });
    } else {
      sessionStorage.setItem("currentPath", this.props.location.pathname);
      this.props.history.push(AppRoutes.SIGN_IN);
    }
  }

  /**
   * redirect to SignIn page
   * @public
   * @param
   */
  public onSignIn(): void {
    this.props.history.push(AppRoutes.SIGN_IN);
  }

  render(): JSX.Element {
    const screenWidth = window.screen.width;
    const userId: string =
      sessionStorage.getItem(AppConstants.USER_ID) ||
      this.store.getUserParam?.userId ||
      this.store.getUserIdSignup ||
      "";
    const loading: boolean = this.store.getLoading;
    const offset: number = window.pageYOffset;
    return (
      <React.Fragment>
        <Grid container className="about-page">
          <Grid container item xs={12} className="about-header">
            <Box className="about-box">
              <Box className="header-box_logo">
                <LogoApi />
              </Box>
              {screenWidth < 420 ? (
                <MobileMenuApp userId={userId} />
              ) : (
                <MenuApp option={MenuOptions.ABOUT_US} userId={userId} />
              )}
              {userId ? (
                <SignIn
                  onSignIn={this.onSignOut}
                  title={MenuOptions.SIGN_OUT}
                />
              ) : (
                <SignIn onSignIn={this.onSignIn} title={MenuOptions.SIGN_IN} />
              )}
            </Box>
          </Grid>
          <Grid container item xs={12} className="about-section">
            <MyDataUsCard passFunction={this.onDeleteAcc} />
          </Grid>
          <Grid container item xs={12} className="slider-review">
            <SliderClient />
          </Grid>
          <Footer option={MenuOptions.ABOUT_US} userId={userId} />
          {this.state.showDeleteAccDialog && (
            <DeleteAccDialog
              onCloseDialog={() =>
                this.setState({ showDeleteAccDialog: false })
              }
            />
          )}
          {loading && <CircularLoading offset={offset} />}
        </Grid>
      </React.Fragment>
    );
  }
}
