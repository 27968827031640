import { Box, Typography } from "@material-ui/core";
import React, { PureComponent } from "react";
import TransactionItem from "../../transactions/TransactionItem";
import { v4 } from "uuid";

import "./MyDataUsCard.scss";
import Australia from "./../../../accets/images/australia.svg";
import ConfirmButton from "../../button/ConfirmButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AppRoutes } from "../../../app/AppRoutes";
import { Link } from "react-router-dom";
import AppConstants from "../../../app/AppConstants";

export interface IProps extends RouteComponentProps {
  passFunction?: Function;
}
export interface IState {}

class MyDataUsCard extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  handleSubmit = () => {
    if (this.props.passFunction) this.props.passFunction();
  };
  render(): JSX.Element {
    return (
      <div className="about-card">
        <Box className="ac-block">
          <Typography className="ac-title">Ugo Data</Typography>
          <Typography className="ac-context">
            {" "}
            Below is Ugo’s policy for data retention. To inquire about the type
            of data Ugo holds about you, or to request certain data be deleted,
            send an email to ugo@ugo.money with the subject Data Request. A user
            can request Ugo to delete their data. Some data for example, related
            to transactions and credit inquiries is required under regulation to
            be retained for up to 7 years.
          </Typography>
          <Typography className="ac-context">
            See the policy below for more about how Ugo stores and protects your
            data.
          </Typography>
          <Typography className="r-title">Ugo Data Retention Policy</Typography>
          <Box className="ac-context">
            <ol>
              <li>Objective</li>
              <p>
                The data retention policy aims to ensure that Ugo effectively
                manages and retains data in compliance with regulatory
                requirements, while also safeguarding client information and
                minimizing risks associated with data storage.
              </p>
              <li>Scope:</li>
              <p>
                This policy applies to all employees, contractors, and
                third-party vendors who handle or have access to data within
                Ugo.
              </p>
              <li>Data Classification:</li>
              <p>
                Data will be classified based on its sensitivity, regulatory
                requirements, and business needs into:
              </p>
              <ul>
                <li>
                  Confidential : Information requiring the highest level of
                  protection, such as client financial data, personal
                  identification information (PII), and proprietary business
                  data.
                </li>
                <li>
                  Internal Use : Information necessary for business operations
                  but not subject to regulatory requirements, such as internal
                  communications and non-sensitive financial reports.
                </li>
                <li>
                  Public : Information intended for public consumption, such as
                  marketing materials and general company information.
                </li>
              </ul>
              <li>Retention Periods:</li>
              <ul>
                <li>
                  Confidential Data : Retained for the duration required by
                  relevant regulations or as necessary for the purpose it was
                  collected. Once the retention period expires, data will be
                  securely destroyed or anonymized, unless there is a legal or
                  regulatory requirement to retain it further.
                </li>
                <li>
                  Internal Use Data : Retained for a period of 7 years, after
                  which it will be reviewed for deletion or retention based on
                  business needs.
                </li>
                <li>
                  Public Data : Retained indefinitely unless there is a business
                  need to remove or update it.
                </li>
              </ul>
              <li>Data Storage and Security:</li>
              <ul>
                <li>
                  All data, regardless of classification, will be stored
                  securely using industry-standard encryption and access
                  controls.
                </li>
                <li>
                  Access to confidential data will be restricted to authorized
                  personnel on a need-to-know basis.
                </li>
                <li>
                  Regular audits and assessments will be conducted to ensure
                  compliance with security protocols and regulatory
                  requirements.
                </li>
              </ul>
              <li>Data Disposal:</li>
              <ul>
                <li>
                  Confidential data will be securely destroyed using approved
                  methods such as shredding or digital wiping once it has
                  exceeded the retention period or is no longer needed for
                  business purposes
                </li>
                <li>
                  Non-confidential data will be deleted or anonymized according
                  to established retention schedules.
                </li>
              </ul>
              <li>Training and Awareness:</li>
              <ul>
                <li>
                  All employees will receive training on data retention policies
                  and procedures to ensure compliance and understanding of their
                  responsibilities.
                </li>
                <li>
                  Regular reminders and updates will be provided to employees to
                  reinforce best practices for data handling and retention.
                </li>
              </ul>
              <li>Policy Review: </li>
              <ul>
                <li>
                  This policy will be reviewed annually or as needed to ensure
                  alignment with regulatory changes, industry best practices,
                  and business requirements.
                </li>
              </ul>
              <li>Enforcement:</li>
              <ul>
                <li>
                  Violations of this policy may result in disciplinary action,
                  up to and including termination of employment, and legal
                  consequences in cases of gross negligence or intentional
                  misconduct.
                </li>
              </ul>
              <li>Approval:</li>
              <ul>
                <li>
                  This data retention policy is approved by Ugo’s Compliance
                  Officer and is effective from Jan 1 2024.. Amendments to this
                  policy require approval from Ugo’s Compliance Officer.
                </li>
              </ul>
            </ol>
          </Box>

          <Box className="identity-confirmation">
            <ConfirmButton
              title="Request Deletion"
              width={"250px"}
              passFunction={this.handleSubmit}
            />
          </Box>
        </Box>
        <Box className="ac-block move">
          <img src={Australia} className="ac-picture" alt="australia" />
        </Box>
      </div>
    );
  }
}

export default withRouter(MyDataUsCard);
