import React from "react"
import { LinearProgress } from "@material-ui/core"

export interface LoadingProps {}

export const Loading: React.FunctionComponent<LoadingProps> = (props) => {
	return (
		<div style={{ position: "absolute", width: "100%" }}>
			<LinearProgress />
		</div>
	)
}
