import React, { Component } from "react"
import "./MenuApp.scss"
import { Button } from "@material-ui/core"
import { AppRoutes } from "../../app/AppRoutes"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { MenuAppType, MenuOptions } from "../../app/AppConstants"

interface IProps extends RouteComponentProps {
	option?: string
	userId?: string | null
}
interface IState {
	value: string
}

class MenuApp extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)
		this.state = {
			value: "Home",
		}

		this.toggleTab = this.toggleTab.bind(this)
		this.redirectToNextPage = this.redirectToNextPage.bind(this)
	}

	componentDidMount() {
		const isOption: string = this.props.option ?? ""
		if (isOption) {
			this.setState((state) => {
				return { value: isOption }
			})
		}
	}
	/**
	 * redirect to next page by link in menu
	 * @public
	 * @param router
	 */
	public redirectToNextPage(router: MenuAppType): Function {
		const redirect = {
			home: () => this.props.history.push(AppRoutes.HOME),
			about: () => this.props.history.push(AppRoutes.ABOUT_UGO),
			contact: () => this.props.history.push(AppRoutes.CONTACT_US),
			faq: () => this.props.history.push(AppRoutes.FAQ),
			message: () => this.props.history.push(AppRoutes.MESSAGE),
			new: () => this.props.history.push(AppRoutes.SIGN_UP_NEW_LOAN),
			dashboard: () => this.props.history.push(AppRoutes.DASHBOARD),
		}
		return redirect[router]
	}

	/**
	 * to change state for choose proper class
	 * @private
	 * @param event
	 */
	private toggleTab(event: any): void {
		console.log("newValue", event.currentTarget.value)
		const { value } = event.currentTarget
		const { innerText } = event.target
		this.setState((state) => {
			return { value: value }
		})
		const isRoute: MenuAppType = innerText.toLowerCase().split(" ")[0]
		this.redirectToNextPage(isRoute)()
	}

	render() {
		const value = this.state.value
		return (
			<div className='menuApp'>
				<Button
					className={`btn ${value === MenuOptions.HOME ? "btn-active " : "btn-disable"}`}
					value={MenuOptions.HOME}
					disableRipple
					onClick={(e) => this.toggleTab(e)}
				>
					{MenuOptions.HOME}
				</Button>
				<Button
					className={`btn  btn-left  ${
						value === MenuOptions.ABOUT_US ? "btn-active " : "btn-disable"
					}`}
					value={MenuOptions.ABOUT_US}
					disableRipple
					onClick={(e) => this.toggleTab(e)}
				>
					{MenuOptions.ABOUT_US}
				</Button>
				<Button
					className={`btn btn-left  ${
						value === MenuOptions.CONTACT_US ? "btn-active " : "btn-disable"
					}`}
					value={MenuOptions.CONTACT_US}
					disableRipple
					onClick={(e) => this.toggleTab(e)}
				>
					{MenuOptions.CONTACT_US}
				</Button>
				<Button
					className={`btn btn-left  ${
						value === MenuOptions.FAQ ? "btn-active " : "btn-disable"
					}`}
					value={MenuOptions.FAQ}
					disableRipple
					onClick={(e) => this.toggleTab(e)}
				>
					{MenuOptions.FAQ}
				</Button>
				{this.props.userId ? (
					<Button
						className={`btn  btn-left  ${
							value === MenuOptions.DASHBOARD ? "btn-active " : "btn-disable"
						}`}
						value={MenuOptions.DASHBOARD}
						disableRipple
						onClick={(e) => this.toggleTab(e)}
					>
						{MenuOptions.DASHBOARD}
					</Button>
				) : (
					<Button
						className={`btn  btn-left  ${
							value === MenuOptions.NEW_LOAN ? "btn-active " : "btn-disable"
						}`}
						value={MenuOptions.NEW_LOAN}
						disableRipple
						onClick={(e) => this.toggleTab(e)}
					>
						{MenuOptions.NEW_LOAN}
					</Button>
				)}
			</div>
		)
	}
}

export default withRouter(MenuApp)
