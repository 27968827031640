import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import MenuIcon from "@material-ui/icons/Menu"
import "./MenuMobileApp.scss"
import { useHistory } from "react-router-dom"
import { AppRoutes } from "../../app/AppRoutes"
import { MenuAppType, MenuOptions } from "../../app/AppConstants"

const options = [
	MenuOptions.HOME,
	MenuOptions.ABOUT_US,
	MenuOptions.CONTACT_US,
	MenuOptions.FAQ,
	MenuOptions.NEW_LOAN,
]

const ITEM_HEIGHT = 48

interface IProps {
	userId?: string | null
}

export default function MobileMenuu(props: IProps) {
	let history = useHistory()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	if (props.userId) {
		options[options.length - 1] = MenuOptions.DASHBOARD
	}

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const redirectToNextPage = (option: string) => {
		console.log("option", option)
		const isOption = option.toLocaleLowerCase().split(" ")[0] as MenuAppType
		console.log("isOption", isOption)
		const redirect = {
			home: () => history.push(AppRoutes.HOME),
			about: () => history.push(AppRoutes.ABOUT_UGO),
			contact: () => history.push(AppRoutes.CONTACT_US),
			faq: () => history.push(AppRoutes.FAQ),
			message: () => history.push(AppRoutes.MESSAGE),
			new: () => history.push(AppRoutes.SIGN_UP_NEW_LOAN),
			dashboard: () => history.push(AppRoutes.DASHBOARD),
		}
		redirect[isOption]()
	}

	return (
		<div className='menu-mobile'>
			<IconButton
				aria-label='more'
				aria-controls='long-menu'
				aria-haspopup='true'
				onClick={handleClick}
			>
				{/* <MoreVertIcon style={{ color: "white" }} /> */}
				<MenuIcon style={{ color: "white", width: "50px", height: "50px" }} />
			</IconButton>
			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: "20ch",
					},
				}}
			>
				{options.map((option) => (
					<MenuItem
						key={option}
						// selected={option === "Pyxis"}
						onClick={() => redirectToNextPage(option)}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}
