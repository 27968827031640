export enum AppRoutes {
  HOME = "/",
  SIGN_IN = "/sign-in",
  //Dashboard
  DASHBOARD = "/dashboard",
  PERSONAL_DETAILS = "/dashboard-personal-details",
  TRANSACTIONS = "/dashboard-transactions",
  MAKE_PAYMENT = "/dashboard-make-payment",
  MESSAGE = "/dashboard-message",
  NOTIFICATIONS = "/dashboard-notifications",
  NEW_LOAN = "/dashboard-new-loan",
  //About ugo
  ABOUT_UGO = "/about-ugo",
  // mydata
  MY_DATA = "/mydata",

  //Contact us
  CONTACT_US = "/contact-us",
  //Privacy policy
  PRIVACY_POLICY = "/privacy-policy",

  //faq
  FAQ = "/faq",

  //Sign UP
  SIGN_UP_NEW_LOAN = "/sign-up-new-loan",
  SIGN_UP_PERSONAL_DETAILS = "/sign-up-personal-details",
  SIGN_UP_LOAN_PARTICULARS = "/sign-up-loan-particulars",
  SIGN_UP_IDENTITY = "/sign-up-identity",
  SIGN_UP_BANK_STATEMENTS = "/sign-up-bank-statements",
  SIGN_UP_EMPLOYER = "/sign-up-employer",
  SIGN_UP_ADDITIONAL_CONTACT = "/sign-up-additional-contact",
  SIGN_UP_CENTERLINK = "/sign-up-centerlink",
  SIGN_UP_AGREEMENT = "/agreement",
  SIGN_UP_SIGNATURE = "/signature",
  SIGN_UP_FINISH = "/sign-up-finish",

  //recover password
  FORGOT_PASSWORD = "/forgot-password",
  NEW_PASSWORD = "/new-password",

  //refreshBankFeeds
  REFRESH_BANK_FEEDS = "/loan-application/adminUgo",
  REFRESH_BANK_FEEDS_CONFIRMATION = "/refresh-confirmation",
}
