import React, { PureComponent } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import MuiDialogContent from "@material-ui/core/DialogContent";

import Typography from "@material-ui/core/Typography";
import "./Dialog.scss";

import { IAppStore } from "../../stores/AppStore";
import { inject, observer } from "mobx-react";
//import { Button, DialogActions } from "@mui/material"
import { Button, DialogActions } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export interface IProps {
  appStore?: IAppStore;
  nameButtonOne?: string;
  onCloseDialog?: Function;
}

export interface IState {}

@inject("appStore")
@observer
export default class DeleteAccDialog extends PureComponent<IProps, IState> {
  store: IAppStore;
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.store = props.appStore!;
    this.handleClose = this.handleClose.bind(this);
  }

  public handleClose = () => {
    if (this.props.onCloseDialog) this.props.onCloseDialog();
    this.store.setOpenDialodCookies(false);
  };

  render(): JSX.Element {
    const open = this.store.getOpenDialogCookies;
    const message = this.store.getMessageDialogCookies;

    return (
      <div>
        <Dialog
          className="emergency-popup"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent
            style={{ textAlign: "center" }}
            className="signUp-dialog_statement"
          >
            <Typography className="context">{message}</Typography>
          </DialogContent>
          <DialogActions className="dialog-btn">
            <Button
              disableRipple
              className="btn"
              onClick={this.handleClose}
              autoFocus
            >
              {this.props.nameButtonOne ?? "OK"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
