import { action, computed, observable, configure, makeAutoObservable } from "mobx"
import {
	IBankInstruction,
	IBankInstructionResponse,
	IBankStatement,
	ICalculatorDetails,
	IClientUserToken,
	IDashboardDetails,
	IEmployer,
	IEmployerDetails,
	IHelperCrunchMessages,
	ILoanParticulars,
	ILoanStatus,
	ILogin,
	IMessageHelperCrunch,
	IProfile,
	ISignUp,
	ITestimonials,
	ITransactionDetails,
} from "../data/models"

// strict mode
configure({ enforceActions: "observed" })

export interface IAppStore {
	//loader
	setLoading(loader: boolean): void
	getLoading: boolean

	// dialog
	setOpenDialog(open: boolean): void
	getOpenDialog: boolean
	setUpDialog(message: string): void
	setCloseDialog(): void

	//dialog message
	//setOpenDialogMessage(open: boolean): void
	setDialogMessage(message: string): void
	getOpenDialogMessage: boolean
	setCloseDialogMessage(): void

	//refresh update dialog
	setRefreshUpdateDialog(open: boolean): void
	getRefreshUpdateDialog: boolean

	//confirm dialog
	// setOpenConfirmDialog(open: boolean): void
	// getOpenConfirmDialog: boolean
	// setUpConfirmDialog(message: string): void
	// setCloseConfirmDialog(): void

	//message
	setMessage(message: string): void
	getMessage: string

	//confirm message
	// setConfirmMessage(message: string): void
	// getConfirmMessage: string

	//user param
	setUserParam(user: ILogin | null): void
	getUserParam: ILogin | null

	//dashboardDetails
	setDashboardDetails(data: IDashboardDetails[]): void
	getDashboardDetails: IDashboardDetails[]

	//profile
	setProfile(data: IProfile): void
	getProfile: IProfile | null

	//transactions
	setTransactions(transactions: ITransactionDetails[]): void
	getTransactions: ITransactionDetails[]

	//confirmation e-signed
	setConfirnationEsignedUp(confirmation: boolean): void
	getConfirnationEsignedUp: boolean

	//calculateLoan
	setCalculationLoan(calculation: ICalculatorDetails): void
	getCalculationLoan: ICalculatorDetails | null

	//loan particulars
	setLoanParticulars(loan: ILoanParticulars): void
	getLoanParticulars: ILoanParticulars | null

	//personal details
	setPersonalDetails(details: ISignUp): void
	getPersonalDetails: ISignUp | null

	//user id sign up
	setUserIdSignup(userId: string): void
	getUserIdSignup: string

	//password
	setPassword(password: string): void
	getPassword: string

	//setclientysertoken
	setClienUserToken(data: IClientUserToken | null): void
	getClientUserToken: IClientUserToken | null

	//message confirmation
	setVerificationCodeEmail(data: string): void
	getVerificationCodeEmail: string

	//bankStatemet
	setListOfBankStatemet(data: IBankStatement[]): void
	getListOfBankStatemet: IBankStatement[]

	//save banks instructions
	setSaveBankInstruction(data: IBankInstruction): void
	getSaveBankInstruction: IBankInstruction | null

	//loan details and status
	getLoanStatus: ILoanStatus | null
	setLoanStatus(data: ILoanStatus): void

	//checkPersonalDialog
	setOpenCheckPersonalDialog(open: boolean): void
	setMessagePersonalDialog(message: string): void
	setCloseCheckPersonalDialog(): void
	getMessageCheckPersonalDialog: string
	getOpenCheckPersonalDialog: boolean

	//restore password
	setMessageRestorePassword(message: string): void
	getMessageRestorePassword: string
	setOpenDialogRestorePassword(action: boolean): void
	getOpenDialogRestorePassword: boolean

	//toggle for redirection to forgotPassword page
	setChangePassword(): void
	getChangePassword: boolean

	//confirmPasswordToRestore
	setConfirmPasswordToRestore(confirm: boolean): void
	getConfirmPasswordToRestore: boolean

	//employee
	setEmployerDetails(employee: IEmployerDetails | null): void
	getEmployerDetails: IEmployerDetails | null

	//helperCrunch
	setHelperCrunchMessages(messages: IHelperCrunchMessages[]): void
	getHelperCrunchMessages: IHelperCrunchMessages[]

	//new loanApplication Id after start new loan
	setLoanApplicationId(loanApplicationId: string): void
	getLoanApplicationId: string

	// inProgress
	setInProgress(progress: boolean): void
	getInProgress: boolean

	//dialog cookies
	setOpenDialodCookies(open: boolean): void
	getOpenDialogCookies: boolean
	setMessageDialogCookies(msg: string): void
	getMessageDialogCookies: string

	//helper crunch======OLD=====
	setCustomerHelperCrunch(customer: number): void
	setChatHelperCrunch(chatId: number): void
	getCustomerHelperCrunch: number | null
	getChatHelperCrunch: number | null
	setMessagesHelperCrunch(messages: IMessageHelperCrunch[]): void
	getMessagesHelperCrunch: IMessageHelperCrunch[]
	setSendMessageHelperCrunch(message: string): void
	getSendMessageHelperCrunch: string

	//testimonials
	setTestimonials(reviews: ITestimonials[]): void
	getTestimonials: ITestimonials[]

	//erasing data
	clear(): void
}

export default class AppStore implements IAppStore {
	constructor() {
		makeAutoObservable(this)
	}

	@observable loader: boolean = false
	@observable user: ILogin | null = null
	@observable dashboardDetails: IDashboardDetails[] = []
	@observable profile: IProfile | null = null
	@observable transactions: ITransactionDetails[] = []
	@observable esignedUp: boolean = false
	@observable calculationLoan: ICalculatorDetails | null = null
	@observable openDialog: boolean = false
	@observable openDialogMessage: boolean = false
	@observable openRefreshUpdateDialog: boolean = false
	// @observable openConfirmDialog: boolean = false
	@observable dialogMessage: string = ""
	// @observable confirmMessage: string = ""
	@observable loanParticulars: ILoanParticulars | null = null
	@observable personalDetails: ISignUp | null = null
	@observable userIdSignUp: string = ""
	@observable password: string = ""
	@observable clientUserToken: IClientUserToken | null = null
	@observable verificationCodeEmail: string = ""
	@observable loadFirstDocument: string | null = null
	@observable loadSecondDocument: string | null = null
	@observable loadThirdDocument: string | null = null

	@observable listOfBankStatemet: IBankStatement[] = []
	@observable saveBankInstruction: IBankInstruction | null = null

	@observable loanStatus: ILoanStatus | null = null

	@observable openCheckPersonalDialog: boolean = false
	@observable messagePersonalDialog: string = ""

	@observable sendMessageHelperCrunch: string = ""

	@observable messageRestorePassword: string = ""
	@observable openDialogRestorePassword: boolean = false

	@observable changePassword: boolean = false

	@observable confirmPasswordToRestore: boolean = false

	@observable employerDetails: IEmployerDetails | null = null

	@observable loanApplicationId: string = ""

	@observable inProgress: boolean = true

	//dialog cookies
	@observable isDialodCookies: boolean = false
	@observable messageDialogCookies: string = ""

	//helperCrunch
	@observable helperCrunchMessages: IHelperCrunchMessages[] = []
	@observable customerHelperCrunch: number | null = null
	@observable chatHelperCrunch: number | null = null
	@observable messagesHelperCrunch: IMessageHelperCrunch[] = []

	//testimonials
	@observable testimonials: ITestimonials[] = []

	//loader
	@action
	public setLoading(loader: boolean): void {
		this.loader = loader
	}
	@computed
	public get getLoading(): boolean {
		return this.loader
	}

	//error dialog
	@action
	public setOpenDialog(open: boolean): void {
		this.openDialog = open
	}

	@computed
	public get getOpenDialog(): boolean {
		return this.openDialog
	}

	@action
	public setUpDialog(message: string): void {
		this.setMessage(message)
		this.setOpenDialog(true)
	}
	@action
	public setCloseDialog(): void {
		this.setMessage("")
		this.setOpenDialog(false)
	}

	//dialog message
	@action
	private setOpenDialogMessage(open: boolean): void {
		this.openDialogMessage = open
	}

	@action
	public setDialogMessage(message: string): void {
		this.setMessage(message)
		this.setOpenDialogMessage(true)
	}

	@computed
	public get getOpenDialogMessage(): boolean {
		return this.openDialogMessage
	}

	@action
	public setCloseDialogMessage(): void {
		this.setMessage("")
		this.setOpenDialogMessage(false)
	}

	//refresh update dialog
	@action
	public setRefreshUpdateDialog(open: boolean): void {
		this.openRefreshUpdateDialog = open
	}

	@computed
	public get getRefreshUpdateDialog(): boolean {
		return this.openRefreshUpdateDialog
	}

	//confirm dialog
	// @action
	// public setOpenConfirmDialog(open: boolean): void {
	// 	this.openConfirmDialog = open
	// }

	// @computed
	// public get getOpenConfirmDialog(): boolean {
	// 	return this.openConfirmDialog
	// }

	// @action
	// public setUpConfirmDialog(message: string): void {
	// 	this.setConfirmMessage(message)
	// 	this.setOpenConfirmDialog(true)
	// }
	// @action
	// public setCloseConfirmDialog(): void {
	// 	this.setConfirmMessage("")
	// 	this.setOpenConfirmDialog(false)
	// }

	//error message
	@action
	public setMessage(message: string): void {
		this.dialogMessage = message
	}

	@computed
	public get getMessage(): string {
		return this.dialogMessage
	}

	//confirm message
	// @action
	// public setConfirmMessage(message: string): void {
	// 	this.confirmMessage = message
	// }

	// @computed
	// public get getConfirmMessage(): string {
	// 	return this.confirmMessage
	// }

	//user param
	@action
	public setUserParam(user: ILogin | null): void {
		this.user = user
	}
	@computed
	public get getUserParam(): ILogin | null {
		return this.user
	}
	//dashboardDetails
	@action
	public setDashboardDetails(data: IDashboardDetails[]): void {
		this.dashboardDetails = [...data]
	}
	@computed
	public get getDashboardDetails(): IDashboardDetails[] {
		return this.dashboardDetails
	}

	//profile
	@action
	public setProfile(data: IProfile): void {
		this.profile = data
	}

	@computed
	public get getProfile(): IProfile | null {
		return this.profile
	}

	//transactions
	@action
	public setTransactions(transactions: ITransactionDetails[]): void {
		this.transactions = [...transactions]
	}

	@computed
	public get getTransactions(): ITransactionDetails[] {
		return this.transactions
	}

	//confirmation e-signed
	@action
	public setConfirnationEsignedUp(confirmation: boolean): void {
		this.esignedUp = confirmation
	}
	@computed
	public get getConfirnationEsignedUp(): boolean {
		return this.esignedUp
	}

	//calculateLoan
	@action
	public setCalculationLoan(calculation: ICalculatorDetails): void {
		this.calculationLoan = { ...calculation }
	}

	@computed
	public get getCalculationLoan(): ICalculatorDetails | null {
		return this.calculationLoan
	}

	//loan particulars
	@action
	public setLoanParticulars(loan: ILoanParticulars): void {
		this.loanParticulars = { ...loan }
	}

	@computed
	public get getLoanParticulars(): ILoanParticulars | null {
		return this.loanParticulars
	}

	//personal details
	@action
	public setPersonalDetails(details: ISignUp): void {
		this.personalDetails = { ...details }
	}

	@computed
	public get getPersonalDetails(): ISignUp | null {
		return this.personalDetails
	}

	//user id sign up
	@action
	public setUserIdSignup(userId: string): void {
		this.userIdSignUp = userId
	}

	@computed
	public get getUserIdSignup(): string {
		return this.userIdSignUp
	}

	//password
	@action
	public setPassword(password: string): void {
		this.password = password
	}

	@computed
	public get getPassword(): string {
		return this.password
	}

	// client data after second request sign up setClientUserToken
	@action
	public setClienUserToken(data: IClientUserToken | null): void {
		this.clientUserToken = data
	}

	@computed
	public get getClientUserToken(): IClientUserToken | null {
		return this.clientUserToken
	}

	//message confirmation
	@action
	public setVerificationCodeEmail(data: string): void {
		this.verificationCodeEmail = data
	}

	@computed
	public get getVerificationCodeEmail(): string {
		return this.verificationCodeEmail
	}

	//loading documents
	@action
	public setLoadFirstDocument(data: string): void {
		this.loadFirstDocument = data
	}
	public setSecondDocument(data: string): void {
		this.loadSecondDocument = data
	}
	public setThirdDocument(data: string): void {
		this.loadThirdDocument = data
	}

	@computed
	public get getLoadFirstDocument(): string | null {
		return this.loadFirstDocument
	}
	@computed
	public get getLoadSecondDocument(): string | null {
		return this.loadSecondDocument
	}
	@computed
	public get getLoadThirdDocument(): string | null {
		return this.loadThirdDocument
	}

	//BankStatemet
	@action
	public setListOfBankStatemet(data: IBankStatement[]): void {
		this.listOfBankStatemet = [...data]
	}
	@computed
	public get getListOfBankStatemet(): IBankStatement[] {
		return this.listOfBankStatemet
	}

	//save banks instructions
	@action
	public setSaveBankInstruction(data: IBankInstruction): void {
		this.saveBankInstruction = { ...data }
	}
	@computed
	public get getSaveBankInstruction(): IBankInstruction | null {
		return this.saveBankInstruction
	}

	//loan details and status
	@action
	public get getLoanStatus(): ILoanStatus | null {
		return this.loanStatus
	}
	@computed
	public setLoanStatus(data: ILoanStatus): void {
		this.loanStatus = { ...data }
	}

	//checkPersonalDialog
	@action
	public setOpenCheckPersonalDialog(open: boolean): void {
		this.openCheckPersonalDialog = open
	}

	@action
	public setMessagePersonalDialog(message: string): void {
		this.messagePersonalDialog = message
		this.setOpenCheckPersonalDialog(true)
	}

	@action
	public setCloseCheckPersonalDialog(): void {
		this.setMessagePersonalDialog("")
		this.setOpenCheckPersonalDialog(false)
	}

	@computed
	public get getMessageCheckPersonalDialog(): string {
		return this.messagePersonalDialog
	}

	@computed
	public get getOpenCheckPersonalDialog(): boolean {
		return this.openCheckPersonalDialog
	}

	//restore message

	@action
	public setMessageRestorePassword(message: string): void {
		this.messageRestorePassword = message
		this.setOpenDialogRestorePassword(true)
	}

	@computed
	public get getMessageRestorePassword(): string {
		return this.messageRestorePassword
	}

	@action
	public setOpenDialogRestorePassword(action: boolean): void {
		this.openDialogRestorePassword = action
	}
	@computed
	public get getOpenDialogRestorePassword(): boolean {
		return this.openDialogRestorePassword
	}

	//toggle for redirection to forgotPassword page
	@action
	public setChangePassword(): void {
		this.changePassword = !this.changePassword
	}

	@computed
	public get getChangePassword(): boolean {
		return this.changePassword
	}

	//confirmPasswordToRestore
	@action
	public setConfirmPasswordToRestore(confirm: boolean): void {
		this.confirmPasswordToRestore = confirm
	}

	@computed
	public get getConfirmPasswordToRestore(): boolean {
		return this.confirmPasswordToRestore
	}

	//Employee
	@action
	public setEmployerDetails(employee: IEmployerDetails | null): void {
		this.employerDetails = employee
	}
	@computed
	public get getEmployerDetails(): IEmployerDetails | null {
		return this.employerDetails
	}

	//helperCrunch
	@action
	public setHelperCrunchMessages(messages: IHelperCrunchMessages[]): void {
		this.helperCrunchMessages = [...messages]
	}

	@computed
	public get getHelperCrunchMessages(): IHelperCrunchMessages[] {
		return this.helperCrunchMessages
	}

	//hepler crunch=======OLD===========
	@action
	public setCustomerHelperCrunch(customer: number): void {
		this.customerHelperCrunch = customer
	}
	@action
	public setChatHelperCrunch(chatId: number): void {
		this.chatHelperCrunch = chatId
	}

	@action
	public setMessagesHelperCrunch(messages: IMessageHelperCrunch[]): void {
		this.messagesHelperCrunch = [...messages]
	}

	@computed
	public get getCustomerHelperCrunch(): number | null {
		return this.customerHelperCrunch
	}
	@computed
	public get getChatHelperCrunch(): number | null {
		return this.chatHelperCrunch
	}

	@computed
	public get getMessagesHelperCrunch(): IMessageHelperCrunch[] {
		return this.messagesHelperCrunch
	}

	//send message  HelperCrunch

	@action
	public setSendMessageHelperCrunch(message: string): void {
		this.sendMessageHelperCrunch = message
	}

	@computed
	public get getSendMessageHelperCrunch(): string {
		return this.sendMessageHelperCrunch
	}

	//New loanApplication Id after start new loan
	@action
	public setLoanApplicationId(loanApplicationId: string): void {
		this.loanApplicationId = loanApplicationId
	}

	@computed
	public get getLoanApplicationId(): string {
		return this.loanApplicationId
	}
	// inProgress
	@action
	public setInProgress(progress: boolean): void {
		this.inProgress = progress
	}

	@computed
	public get getInProgress(): boolean {
		return this.inProgress
	}

	//dialog cookies
	@action
	public setOpenDialodCookies(open: boolean): void {
		this.isDialodCookies = open
	}
	@computed
	public get getOpenDialogCookies(): boolean {
		return this.isDialodCookies
	}

	@action
	public setMessageDialogCookies(msg: string): void {
		this.messageDialogCookies = msg
	}
	@computed
	public get getMessageDialogCookies(): string {
		return this.messageDialogCookies
	}

	//testimonials

	@action
	public setTestimonials(reviews: ITestimonials[]): void {
		this.testimonials = reviews
	}
	@computed
	public get getTestimonials(): ITestimonials[] {
		return this.testimonials
	}

	//
	//erasing data
	@action
	public clear(): void {
		this.user = null
		this.dashboardDetails = []
		this.profile = null
		this.transactions = []
		this.esignedUp = false
		this.calculationLoan = null
		this.openDialog = false
		this.openDialogMessage = false
		this.openRefreshUpdateDialog = false
		this.dialogMessage = ""
		this.loanParticulars = null
		this.personalDetails = null
		this.userIdSignUp = ""
		this.password = ""
		this.clientUserToken = null
		this.verificationCodeEmail = ""
		this.loadFirstDocument = null
		this.loadSecondDocument = null
		this.loadThirdDocument = null
		this.listOfBankStatemet = []
		this.saveBankInstruction = null
		this.loanStatus = null
		this.openCheckPersonalDialog = false
		this.messagePersonalDialog = ""
		this.sendMessageHelperCrunch = ""
		this.messageRestorePassword = ""
		this.openDialogRestorePassword = false
		this.changePassword = false
		this.confirmPasswordToRestore = false
		this.employerDetails = null
		this.loanApplicationId = ""
		this.inProgress = true
		this.isDialodCookies = false
		this.messageDialogCookies = ""
		//helperCrunch
		this.helperCrunchMessages = []
		this.customerHelperCrunch = null
		this.chatHelperCrunch = null
		this.messagesHelperCrunch = []
		//testimonials
		this.testimonials = []
	}
}
