export default class AppConstants {
	static CLIENT_DATA = "client"
	static USER_PARAM = "user_param"
	static DashboardDetails = "dashboardDetails"
	static LoanStatus = "loanStatus"
	static Profile = "profile"
	static CLIENT_ID_TOKEN = "token"
	static USER_ID = "user_id"
	static PROGRESS = "progress"
	// static SOUND_OK = "https://sklad.gate-wear.com/common-ui/sounds/ZvukOK.wav";
	// static SOUND_WARN = "https://sklad.gate-wear.com/common-ui/sounds/ZvukPOZOR.wav";
	// static SOUND_ERR = "https://sklad.gate-wear.com/common-ui/sounds/ZvukCHYBA.wav";
	// static CARD_LOGIN_CODE_LENGTH = 13;
	static STAFF_ID_TOKEN = "staff_id_token"
}

export type OptionType =
	| "dashboard"
	| "personal"
	| "transactions"
	| "make"
	| "message"
	| "notifications"

export type MenuAppType = "home" | "about" | "contact" | "message" | "new" | "dashboard" | "faq"

export enum MenuOptions {
	HOME = "Home",
	ABOUT_US = "About Ugo",
	CONTACT_US = "Contact Us",
	POLICIES = "Privacy policy",
	NEW_LOAN = "New Loan",
	DASHBOARD = "Dashboard",
	PERSONAL_DETAILS = "Personal Details",
	TRANSACTIONS = "Transactions",
	MAKE_PAYMENT = "Make a Payment",
	MESSAGE = "Message",
	NOTIFICATIONS = "Notifications",
	SIGN_OUT = "Sign Out",
	SIGN_IN = "Sign In",
	FAQ = 'FAQ'
}

export class TypeLoanIcon {
	static registration_insurance = "00"
	static car_purchase = "01"
	static car_repairs = "02"
	static home_repairs = "10"
	static moving_costs = "11"
	static rent = "12"
	static rental_bond = "13"
	static council_rates = "14"
	static household_furniture = "20"
	static white_goods = "21"
	static tv_stereo_electrical = "22"
	static utility_bills = "30"
	static professional_services = "31"
	static entertainment = "40"
	static travel = "41"
	static overseas = "42"
	static medical_dental = "50"
	static education = "51"
	static wedding = "60"
	static birthday = "61"
	static gifts = "62"
	static funeral_expenses = "63"
	static pay_bank_loan = "70"
	static pay_credit_card = "71"
	static pay_short_term_loan = "72"
}

export enum StepIDProcess {
	Incomplete_View = "993F70C3-B5C5-4282-8AEA-F75D87C6F60B",
	Loan_Calculator = "F5220749-7A94-4F70-8602-3C644BEB217D",
	Personal_Details = "955B8C63-40D0-4A6B-8AAA-45B446988014",
	My_Contact_Details_Are = "38FC2513-3CCD-4C6F-8F62-48DC03E44C3F",
	Create_Password = "441D04BC-FC9D-4731-8B98-50C3A0E0E17B",
	Loan_Particulars = "D7508C74-26DE-463C-8627-5847E6A3D073",
	Identity_Verification = "E132BE97-3FDB-4A6D-A333-466FF3F5DAD6",
	Bank_Statements = "8254A1A6-D2EA-43D6-A26C-629EF4E23BC4",
	Centrelink_Credentials = "A6CBD728-67D7-4C21-8679-35F9A8066688",
	Employer_Details = "CAB9A373-7948-400F-9284-A65964612197",
	Additional_Contact = "E7021863-81CA-44D1-9A40-F2B61D695A04",
	Additional_Contact_2 = "58AC2203-D0B8-4D34-82B7-29FF7BD40A8F",
	Your_Loan_Agreement = "EBAB5A80-9EF6-43D2-8143-5B0B0B790CAF",
}
