import { AxiosRequestConfig } from "axios";
import AppConstants from "./../app/AppConstants";

export default class BaseApi {
  static HOST: string = BaseApi.init();

  static HOST_HELPERCRUNCH: string = BaseApi.initHelperCrunch();

  /**
   * Set proper REST API endpoint based on app mode
   */
  private static init(): string {
    console.info("ENV:", process.env.NODE_ENV);
    let REST_ENDPOINT = "https://api3.ugo.money";
    console.info("REST API ENDPOINT:", REST_ENDPOINT);
    return REST_ENDPOINT;
    if (process.env.NODE_ENV === "production") {
      REST_ENDPOINT = "https://api3.ugo.money";
      console.info("ENV:", process.env.NODE_ENV);
      console.info("REST_ENDPOINT:", REST_ENDPOINT);
      //REST_ENDPOINT = "http://localhost:3008"
      //REST_ENDPOINT = "http://0.0.0.0:3008"
      //REST_ENDPOINT = "https://api2-dev.ugo.money"
      //REST_ENDPOINT = "https://api2-preprod.ugo.money"
    } else if (process.env.NODE_ENV === "development") {
      // REST_ENDPOINT = "https://api2-preprod.ugo.money"
      REST_ENDPOINT = "https://api2-dev.ugo.money";
      //REST_ENDPOINT = "http://localhost:3008"
      // REST_ENDPOINT = "http://0.0.0.0:3008"
      // REST_ENDPOINT = "https://api2-dev.ugo.money"
      // REST_ENDPOINT = "http://localhost:8080"
    } else if (process.env.NODE_ENV === "test") {
      REST_ENDPOINT = "http://localhost:3008";
      //REST_ENDPOINT = "http://0.0.0.0:3008"
      //REST_ENDPOINT = "https://api2-dev.ugo.money"
      // REST_ENDPOINT = "https://gato.gate-wear.cloud/sklad-doklady-api"
    }

    console.info("REST API ENDPOINT:", REST_ENDPOINT);
    //console.info("client:", window.localStorage.getItem(AppConstants.CLIENT_DATA))

    return REST_ENDPOINT;
  }

  protected static getConfig(): AxiosRequestConfig {
    const HEADER = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
    };

    const AUTH_CONFIG: AxiosRequestConfig = {
      baseURL: BaseApi.HOST,
      headers: HEADER,
    };

    return AUTH_CONFIG;
  }

  protected static getAuthConfig(): AxiosRequestConfig {
    const token: string | null = sessionStorage.getItem(
      AppConstants.CLIENT_ID_TOKEN
    );
    // console.log(" getAuthConfig() token", token)
    if (token) {
      // let token = JSON.parse(userData).token
      const HEADERS = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        //Language: stores.applicationStore.getUserLanguage
      };

      const CONFIG: AxiosRequestConfig = {
        baseURL: BaseApi.HOST,
        headers: HEADERS,
      };

      return CONFIG;
    }
    const CONFIG: AxiosRequestConfig = {
      baseURL: BaseApi.HOST,
    };

    return CONFIG;
  }

  //for loading docunets
  protected static getAuthConfigLoading(): AxiosRequestConfig {
    const userData: string | null =
      sessionStorage.getItem(AppConstants.USER_PARAM) ||
      sessionStorage.getItem(AppConstants.CLIENT_ID_TOKEN);
    if (userData) {
      let token = JSON.parse(userData).token;
      const HEADERS = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
        //Language: stores.applicationStore.getUserLanguage
      };

      const CONFIG: AxiosRequestConfig = {
        baseURL: BaseApi.HOST,
        headers: HEADERS,
      };

      return CONFIG;
    }
    const CONFIG: AxiosRequestConfig = {
      baseURL: BaseApi.HOST,
    };

    return CONFIG;
  }

  //for connecting Event Source
  protected static eventSourceConfig(): AxiosRequestConfig {
    const HEADERS = {
      Accept: "application/json",
      "Content-Type": "Content-Type: text/event-stream",
      //"Cache-Control: no-cache",
      //'Connection: keep-alive'
      //'X-Accel-Buffering: no'
    };

    const EVENT_SOURCE_CONFIG: AxiosRequestConfig = {
      baseURL: BaseApi.HOST,
      headers: HEADERS,
    };

    return EVENT_SOURCE_CONFIG;
  }

  /**
   * Set proper REST API endpoint for connecting to helpercrunch
   */
  public static initHelperCrunch(): string {
    // console.info("helpercranch key:", `${process.env.REACT_APP_HELPERCRUNCH}`)
    let REST_ENDPOINT = "https://api.helpcrunch.com/v1";
    return REST_ENDPOINT;
  }

  protected static getHelperCrunchConfig(): AxiosRequestConfig {
    const HEADER = {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: "Bearer " + process.env.REACT_APP_HELPERCRUNCH,
      //Authorization: `Bearer ${process.env.REACT_APP_HELPERCRUNCH}`,
      Authorization: `Bearer ${process.env.REACT_APP_HELPERCRUNCH_UGO}`,
      //Language: stores.applicationStore.getUserLanguage
    };

    const AUTH_CONFIG: AxiosRequestConfig = {
      baseURL: BaseApi.HOST_HELPERCRUNCH,
      headers: HEADER,
    };

    return AUTH_CONFIG;
  }

  // Working POST example
  // public static log(log: ILog): Promise<ILogResponse> {
  //   const headers = {
  //     'Content-Type': 'application/json'
  //   };
  //
  //   return axios
  //     .post(`${RestApi.HOST}/log`, JSON.stringify(log), {headers: headers})
  //     .then(res => {
  //       return res.data;
  //     })
  //     .then(r => {
  //       return r;
  //     });
  // }
}
